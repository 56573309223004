<template>
    <div>
        <div class="flex items-center justify-center">
            <span class="w-28 h-28 flex items-center justify-center bg-sepiaBlack-lightest">
                <span class="text-white text-xs flex items-center justify-center">sepiaBlack-lightest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-monarch-lightest">
                <span class="text-white text-xs flex items-center justify-center">monarch-lightest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-redDamask-lightest">
                <span class="text-white text-xs flex items-center justify-center">redDamask-lightest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-twine-lightest">
                <span class="text-white text-xs flex items-center justify-center">twine-lightest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-halfColonialWhite-lightest">
                <span class="text-black text-xs flex items-center justify-center">halfColonialWhite-lightest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mercury-lightest">
                <span class="text-black text-xs flex items-center justify-center">mercury-lightest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mineShaft-lightest">
                <span class="text-white text-xs flex items-center justify-center">mineShaft-lightest</span>
            </span>
        </div>
        <br />
        <div class="flex items-center justify-center">
            <span class="w-28 h-28 flex items-center justify-center bg-sepiaBlack-light">
                <span class="text-white text-xs flex items-center justify-center">sepiaBlack-light</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-monarch-light">
                <span class="text-white text-xs flex items-center justify-center">monarch-light</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-redDamask-light">
                <span class="text-white text-xs flex items-center justify-center">redDamask-light</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-twine-light">
                <span class="text-white text-xs flex items-center justify-center">twine-light</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-halfColonialWhite-light">
                <span class="text-black text-xs flex items-center justify-center">halfColonialWhite-light</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mercury-light">
                <span class="text-black text-xs flex items-center justify-center">mercury-light</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mineShaft-light">
                <span class="text-white text-xs flex items-center justify-center">mineShaft-light</span>
            </span>
        </div>
        <br />
        <div class="flex items-center justify-center">
            <span class="w-28 h-28 flex items-center justify-center bg-sepiaBlack">
                <span class="text-white text-xs flex items-center justify-center">sepiaBlack</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-monarch">
                <span class="text-white text-xs flex items-center justify-center">monarch</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-redDamask">
                <span class="text-white text-xs flex items-center justify-center">redDamask</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-twine">
                <span class="text-white text-xs flex items-center justify-center">twine</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-halfColonialWhite">
                <span class="text-black text-xs flex items-center justify-center">halfColonialWhite</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mercury">
                <span class="text-black text-xs flex items-center justify-center">mercury</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mineShaft">
                <span class="text-white text-xs flex items-center justify-center">mineShaft</span>
            </span>
        </div>
        <br />
        <div class="flex items-center justify-center">
            <span class="w-28 h-28 flex items-center justify-center bg-sepiaBlack-dark">
                <span class="text-white text-xs flex items-center justify-center">sepiaBlack-dark</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-monarch-dark">
                <span class="text-white text-xs flex items-center justify-center">monarch-dark</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-redDamask-dark">
                <span class="text-white text-xs flex items-center justify-center">redDamask-dark</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-twine-dark">
                <span class="text-white text-xs flex items-center justify-center">twine-dark</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-halfColonialWhite-dark">
                <span class="text-black text-xs flex items-center justify-center">halfColonialWhite-dark</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mercury-dark">
                <span class="text-black text-xs flex items-center justify-center">mercury-dark</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mineShaft-dark">
                <span class="text-white text-xs flex items-center justify-center">mineShaft-dark</span>
            </span>
        </div>
        <br />
        <div class="flex items-center justify-center">
            <span class="w-28 h-28 flex items-center justify-center bg-sepiaBlack-darkest">
                <span class="text-white text-xs flex items-center justify-center">sepiaBlack-darkest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-monarch-darkest">
                <span class="text-white text-xs flex items-center justify-center">monarch-darkest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-redDamask-darkest">
                <span class="text-white text-xs flex items-center justify-center">redDamask-darkest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-twine-darkest">
                <span class="text-white text-xs flex items-center justify-center">twine-darkest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-halfColonialWhite-darkest">
                <span class="text-black text-xs flex items-center justify-center">halfColonialWhite-darkest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mercury-darkest">
                <span class="text-black text-xs flex items-center justify-center">mercury-darkest</span>
            </span>
            <span class="w-28 h-28 flex items-center justify-center bg-mineShaft-darkest">
                <span class="text-white text-xs flex items-center justify-center">mineShaft-darkest</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
  name: "Colors"
};
</script>
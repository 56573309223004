<template>
    <div>
        <div class="flex justify-center">
            <h6 class="font-heading text-h6 text-sepiaBlack dark:text-white">Lorem ipsum dolor <br />second line goes here (19px) <br />{font-heading text-h6 text-sepiaBlack }</h6>
        </div>
        <br />
        <div class="flex justify-center">
            <h5 class="font-heading text-h5 text-sepiaBlack dark:text-white">Lorem ipsum dolor <br />second line goes here (24px) <br />{font-heading text-h5 text-sepiaBlack}</h5>
        </div>
        <br />
        <div class="flex justify-center">
            <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white">Lorem ipsum dolor <br />second line goes here (30px) <br />{font-heading text-h4 text-sepiaBlack}</h4>
        </div>
        <br />
        <div class="flex justify-center">
            <h3 class="font-heading text-h3 text-sepiaBlack dark:text-white">Lorem ipsum dolor <br />second line goes here (38px) <br />{font-heading text-h3 text-sepiaBlack}</h3>
        </div>
        <br />
        <div class="flex justify-center">
            <h2 class="font-heading text-h2 text-sepiaBlack dark:text-white">Lorem ipsum dolor <br />second line goes here (48px) <br />{font-heading text-h2 text-sepiaBlack}</h2>
        </div>
        <br />
        <div class="flex justify-center">
            <h1 class="font-heading text-h1 text-sepiaBlack dark:text-white">Lorem ipsum dolor <br />second line goes here (59px) <br />{font-heading text-h1 text-sepiaBlack}</h1>
        </div>
        <br />
        <div class="flex justify-center">
            <p class="font-heading text-subM text-twine font-extrabold">Subtitle M (24px) <br /> {font-heading text-subM text-twine font-extrabold} </p>
        </div>
        <br />
        <div class="flex justify-center">
            <p class="font-heading text-subS text-twine font-extrabold">Subtitle S (16px) <br /> {font-heading text-subS text-twine font-extrabold} </p>
        </div>
        <br />
         <div class="flex justify-center">
            <div class="font-body text-bodyL text-sepiaBlack dark:text-white max-w-md	"><p>Body L (14px): </p>
                <p> Porta aliquet adipiscing sed fringilla ac pulvinar. Feugiat amet, at felis auctor ullamcorper eget mauris. Adipiscing enim, et non sit mauris, diam. Id odio massa tincidunt sed nisl elit diam, est. Blandit ornare aliquam nisl pulvinar. Tristique mauris leo mi urna.</p>
                <p>Szatmár Megyei Múzeum üóőúáűé  ÜÓŐÚÉÁŰ  ăâțșĂÂȚȘ äöüß€ <br /> {font-body text-bodyL text-sepiaBlack} </p>
            </div>
        </div>
        <br />
         <div class="flex justify-center">
            <div class="font-body text-bodyM text-sepiaBlack dark:text-white max-w-md	"><p>Body M (12px):</p> 
                <p>Porta aliquet adipiscing sed fringilla ac pulvinar. Feugiat amet, at felis auctor ullamcorper eget mauris. Adipiscing enim, et non sit mauris, diam. Id odio massa tincidunt sed nisl elit diam, est. Blandit ornare aliquam nisl pulvinar. Tristique mauris leo mi urna.</p> 
                <p> Szatmár Megyei Múzeum üóőúáűé  ÜÓŐÚÉÁŰ  ăâțșĂÂȚȘ äöüß€<br /> {font-body text-bodyM text-sepiaBlack} </p>
            </div>
        </div>
        <br />
         <div class="flex justify-center">
            <div class="font-body text-bodyS text-sepiaBlack dark:text-white max-w-md	"><p>Body S (10px):</p>
                <p>Porta aliquet adipiscing sed fringilla ac pulvinar. Feugiat amet, at felis auctor ullamcorper eget mauris. Adipiscing enim, et non sit mauris, diam. Id odio massa tincidunt sed nisl elit diam, est. Blandit ornare aliquam nisl pulvinar. Tristique mauris leo mi urna.</p>
                <p>Szatmár Megyei Múzeum üóőúáűé  ÜÓŐÚÉÁŰ  ăâțșĂÂȚȘ äöüß€<br /> {font-body text-bodyS text-sepiaBlack} </p>
            </div>
        </div>
        <br />
    </div>
</template>

<script>
export default {
  name: "Typography"
};
</script>
<template>
  <div class="design-system text-center">
    <div class="">
        <h1 class="font-bold text-sepiaBlack dark:text-white text-2xl leading-10 mb-2">Design system</h1>
    </div>
    <div class="flex justify-center mb-3">
      <DarkMode :modeChanged="modeChanged" darkActive= 'dark'/>
    </div>
    <!-- start Colors -->
    <div>
        <h2 class="text-xl font-bold dark:text-white">Colors</h2>
        <span class="dark:text-white">Control the background color of an element using the <span class="font-bold">`bg-{color}`</span> class</span>
        <br />
        <span class="dark:text-white">Control the text color of an element using the <span class="font-bold">`text-{color}`</span> class</span>
        <Colors />
        <br />
    </div>
    <!-- end Colors -->
    <!-- start Icons -->
    <div>
      <h2 class="text-xl font-bold dark:text-white">Icons</h2>
      <pre class="dark:text-white">&lt;BaseIcon name="{icon-name}"/&gt;</pre>
      <br/>
      <pre class="dark:text-white -mt-5">optional outline={ false } bg={ bg-{color} }</pre>
        <div class="grid items-center justify-center grid-cols-9 gap-y-5 mx-20 pt-8">
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="arrowLeft" bg= "bg-redDamask bg-opacity-20" />
            <span class="dark:text-white">
              arrowLeft
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="arrowRight" outline= false />
            <span class="dark:text-white">
              arrowRight
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="extend" />
            <span class="dark:text-white">
              extend
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="plus" />
            <span class="dark:text-white">
              plus
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="close" />
            <span class="dark:text-white">
              close
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="menu" />
            <span class="dark:text-white">
              menu
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="image" />
            <span class="dark:text-white">
              image
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="media" />
            <span class="dark:text-white">
              media
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="sound" />
            <span class="dark:text-white">
              sound
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="3D" />
            <span class="dark:text-white">
              3D
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="home" />
            <span class="dark:text-white">
              home
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="qrCode" />
            <span class="dark:text-white">
              qrCode
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="timeline" />
            <span class="dark:text-white">
              timeline
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="search" />
            <span class="dark:text-white">
              search
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="AR" />
            <span class="dark:text-white">
              AR
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="darkMode" />
            <span class="dark:text-white">
              darkMode
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="lightMode" />
            <span class="dark:text-white">
              lightMode
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="settings" />
            <span class="dark:text-white">
              settings
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="rotate" />
            <span class="dark:text-white">
              rotate
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="headphone" />
            <span class="dark:text-white">
              headphone
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="fastBackward" />
            <span class="dark:text-white">
              fastBackward
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="fastForward" />
            <span class="dark:text-white">
              fastForward
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="stop" />
            <span class="dark:text-white">
              stop
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="pause" />
            <span class="dark:text-white">
              pause
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="back" />
            <span class="dark:text-white">
              back
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="forward" />
            <span class="dark:text-white">
              forward
            </span>
          </span>
          <span class="px-4 text-center mx-auto">
            <BaseIcon name="checkmark" />
            <span class="dark:text-white">
              checkmark
            </span>
          </span>
          <br />
        </div>
        <br />
    </div>
    <!-- end Icons -->
    <!-- start buttons -->
    <div>
      <h2 class="text-xl font-bold dark:text-white">Buttons</h2>
      <pre class="dark:text-white">&lt;Button buttonType="{primary | secondary | tertiary}" text="{ text of the button } /&gt;</pre>
      <br/>
      <pre class="dark:text-white -mt-5">optional xl={ true } href="{ link }"</pre>
      <div class="py-8 grid grid-cols-3 gap-6 mx-52">
        <span>
          <Button href="https://www.google.com/" text="Button" buttonType="primary"/>
          <br/>
          <span class="dark:text-white inline-block">
            primary
          </span>
        </span>
        <span>
          <Button text="Longer button text" buttonType="secondary"/>
          <br/>
          <span class="dark:text-white inline-block">
            secondary
          </span>
        </span>
        <span>
          <Button href="https://www.google.com/" text="Button" buttonType="tertiary"/>
          <br/>
          <span class="dark:text-white inline-block">
            tertiary
          </span>
        </span>
        <span>
          <Button href="https://www.google.com/" text="Big button" buttonType="primary" xl=true />
          <br/>
          <span class="dark:text-white inline-block">
            primary xl
          </span>
        </span>
        <span>
          <Button href="https://www.google.com/" text="Big button" buttonType="secondary" xl=true />
          <br/>
          <span class="dark:text-white inline-block">
            secondary xl
          </span>
        </span>
        <span>
          <Button text="Very long text big button" buttonType="tertiary" xl=true />
          <br/>
          <span class="dark:text-white inline-block">
            tertiary xl
          </span>
        </span>
      </div>
    </div>
    <!-- end buttons -->
    <!-- start inputs -->
    <div>
      <h2 class="text-xl font-bold dark:text-white">Inputs</h2>
      <pre class="dark:text-white">&lt;Input/&gt;</pre>
      <br/>
      <pre class="dark:text-white -mt-5">optional label="{ input label text }" placeholder="{ placeholder text }"</pre>
      <div class="my-10">
        <Input label="This is the label" placeholder="Ghost text"/>
      </div>
    </div>
    <!-- end inputs -->

    <div class="w-6/12 mx-auto">
        <h2 class="text-xl font-bold dark:text-white">Gallery</h2>
        <Gallery/>
    </div>
    <!-- start Typography -->
    <div>
        <h2 class="text-xl font-bold dark:text-white">Typography</h2>
        <span class="dark:text-white">Control the heading size of an element using the<span class="font-bold">`text-{heading}`</span> class</span>
        <br />
        <span class="dark:text-white">Setting font family of an element using the<span class="font-bold">`font-heading`</span> class</span>
        <br />        
        <br />
        <Typography/>
        <br />
    </div>
    <!-- end Typography -->
  </div>
</template>
<script>
// @ is an alias to /src
import Colors from "@/components/Colors.vue";
import BaseIcon from '@/components/BaseIcon.vue';
import Typography from '@/components/Typography.vue';
import DarkMode from '@/components/DarkMode.vue';
import Button from '@/components/Button.vue';
import Input from '@/components/Input.vue'
import Gallery from '@/components/Gallery.vue';

export default {
  name: "DesignSystem",
  components: {
    Colors,
    BaseIcon,
    Typography,
    DarkMode,
    Button,
    Input,
    Gallery,
  },
  mounted() {
    if(localStorage.theme) {
      this.theme = localStorage.theme
    }
  },
  methods: {
    modeChanged(value) {
      this.$emit('modeChanged', value);
    },
  },
  watch: {
    theme(newTheme) {
      localStorage.theme = newTheme;
    }
  }
};
</script>
